import React from 'react'
import { config } from '../../config'
import * as constants from '../../constants/request'
import { Aside, Main, Wrapper } from '../../components/ui/Layout'
import { Helmet } from 'react-helmet'
import { TripRequestManager } from '../../containers/TripRequestManager'
import { TripRequestPageDraft } from './StatusPages/TripRequestPageDraft'
import { TripRequestPageWaitingForAcceptance } from './StatusPages/TripRequestPageWaitingForAcceptance'
import { TripRequestPageUpcomingTrip } from './StatusPages/TripRequestPageUpcomingTrip'
import { TripRequestPageTrip } from './StatusPages/TripRequestPageTrip'
import { TripRequestPageSettlement } from './StatusPages/TripRequestPageSettlement'
import { TripRequestPageAcceptanceSettlement } from './StatusPages/TripRequestPageAcceptanceSettlement'
import { TripRequestPageAccounting } from './StatusPages/TripRequestPageAccounting'
import { RequestBreadcrumb } from './RequestBreadcrumb'
import { TripRequestPageRejected } from './StatusPages/TripRequestPageRejected'
import { TripRequestPageCancelled } from './StatusPages/TripRequestPageCancelled'
import { TripRequestPageFinish } from './StatusPages/TripRequestPageFinish'
import { TripRequestPageTransferred } from './StatusPages/TripRequestPageTransferred'
import { TripRequestPageTransferError } from './StatusPages/TripRequestPageTransferError'
import { TripRequestRightPanel } from './TripRequestRightPanel'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import trans from '../../trans'
import NonDelegationWrapper from '../RequestPageCommon/NonDelegationWrapper'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Icon from '../ui/IconComponent'
import Button from '../ui/ButtonComponent'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { getRouteByName } from '../../routes'

function Transition(props) {
  return <Slide direction='up' {...props} />
}

class TripRequestPage extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isSetAsUnrealized: false,
      type: undefined,
      push: undefined,
    }
  }

  renderDraft(
    request,
    {
      currencies,
      sendToAcceptance,
      currentUser,
      deleteRequest,
      changeRequestValue,
      updateRequest,
      isModuleActive,
      saveDecision,
      ws,
    },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageDraft
        request={request}
        currencies={currencies}
        sendToAcceptance={sendToAcceptance}
        currentUser={currentUser}
        deleteRequest={deleteRequest}
        changeRequestValue={changeRequestValue}
        updateRequest={updateRequest}
        isModuleActive={isModuleActive}
        saveDecision={saveDecision}
        closePopup={closePopup}
        openPopup={openPopup}
        ws={ws}
      />
    )
  }

  renderWaitingForAcceptance(
    request,
    {
      cancelRequest,
      currentUser,
      isModuleActive,
      currencies,
      changeRequestValue,
      instanceCurrency,
    },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageWaitingForAcceptance
        request={request}
        cancelRequest={cancelRequest}
        currentUser={currentUser}
        currencies={currencies}
        isModuleActive={isModuleActive}
        closePopup={closePopup}
        openPopup={openPopup}
        changeRequestValue={changeRequestValue}
        instanceCurrency={instanceCurrency}
      />
    )
  }

  renderUpcomingTrip(
    request,
    {
      sendToSettlement,
      instanceCurrency,
      cancelRequest,
      changeRequestValue,
      fetchRequest,
      unrealizeTrip,
      isModuleActive,
    },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageUpcomingTrip
        request={request}
        instanceCurrency={instanceCurrency}
        sendToSettlement={sendToSettlement}
        cancelRequest={cancelRequest}
        changeRequestValue={changeRequestValue}
        fetchRequest={fetchRequest}
        unrealizeTrip={unrealizeTrip}
        closePopup={closePopup}
        openPopup={openPopup}
        isModuleActive={isModuleActive}
      />
    )
  }

  renderTrip(
    request,
    {
      sendToSettlement,
      instanceCurrency,
      cancelRequest,
      fetchRequest,
      unrealizeTrip,
      isModuleActive,
      changeRequestValue,
    },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageTrip
        request={request}
        instanceCurrency={instanceCurrency}
        sendToSettlement={sendToSettlement}
        cancelRequest={cancelRequest}
        fetchRequest={fetchRequest}
        unrealizeTrip={unrealizeTrip}
        closePopup={closePopup}
        openPopup={openPopup}
        isModuleActive={isModuleActive}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  renderSettlement(
    request,
    {
      instanceCurrency,
      sendToAcceptanceOfSettlement,
      fetchRequest,
      changeRequestValue,
      cancelRequest,
      unrealizeTrip,
      isModuleActive,
    },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageSettlement
        request={request}
        instanceCurrency={instanceCurrency}
        sendToAcceptanceOfSettlement={sendToAcceptanceOfSettlement}
        fetchRequest={fetchRequest}
        changeRequestValue={changeRequestValue}
        cancelRequest={cancelRequest}
        unrealizeTrip={unrealizeTrip}
        closePopup={closePopup}
        openPopup={openPopup}
        isModuleActive={isModuleActive}
      />
    )
  }

  renderAcceptanceOfSettlement(
    request,
    { unrealizeTrip, fetchRequest, isModuleActive, changeRequestValue },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageAcceptanceSettlement
        request={request}
        unrealizeTrip={unrealizeTrip}
        fetchRequest={fetchRequest}
        closePopup={closePopup}
        openPopup={openPopup}
        isModuleActive={isModuleActive}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  renderAccounting(
    request,
    { currentUser, isModuleActive, changeRequestValue, fetchRequest },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageAccounting
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        closePopup={closePopup}
        openPopup={openPopup}
        changeRequestValue={changeRequestValue}
        fetchRequest={fetchRequest}
      />
    )
  }

  renderRejected(
    request,
    { currentUser, isModuleActive, changeRequestValue },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageRejected
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        closePopup={closePopup}
        openPopup={openPopup}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  renderCancelled(
    request,
    { currentUser, isModuleActive, changeRequestValue },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageCancelled
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        closePopup={closePopup}
        openPopup={openPopup}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  renderFinish(
    request,
    { currentUser, isModuleActive, changeRequestValue },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageFinish
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        closePopup={closePopup}
        openPopup={openPopup}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  openPopup = (type, push) => {
    this.setState({
      isSetAsUnrealized: true,
      type,
      push,
    })
  }

  closePopup = (isUnrealizeInProgress) => {
    if (isUnrealizeInProgress) {
      return
    }

    this.setState({ isSetAsUnrealized: false, type: undefined })
  }

  process = (event, request, unrealizeTrip, sendToSettlement, changeRequestValue) => {
    event.preventDefault()

    if (this.state.type === 'setAsUnrealized') {
      return unrealizeTrip(request.slug).then(() => {
        this.closePopup(false)
      })
    } else if (this.state.type === 'sendToSettlement') {
      return sendToSettlement().then(() => {
        this.state.push(getRouteByName('main', `tripRequestsList`))
        this.state.push(getRouteByName('main', 'tripRequestShow', { id: request.slug }))

        this.closePopup(false)
      })
    }

    this.closePopup(false)
    return Promise.resolve()
  }

  renderTransferError(
    request,
    { currentUser, isModuleActive, changeRequestValue },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageTransferError
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        closePopup={closePopup}
        openPopup={openPopup}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  renderTransferred(
    request,
    { currentUser, isModuleActive, changeRequestValue },
    closePopup,
    openPopup,
  ) {
    return (
      <TripRequestPageTransferred
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        closePopup={closePopup}
        openPopup={openPopup}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  render() {
    return (
      <TripRequestManager fetchInitial={this.props.match.params.id} requestType='trip'>
        {({
          request,
          isRequestLoaded,
          currencies,
          isUnrealizeInProgress,
          ws,
          sendToAcceptance,
          sendToSettlement,
          sendToAcceptanceOfSettlement,
          instanceCurrency,
          currentUser,
          deleteRequest,
          cancelRequest,
          changeRequestValue,
          updateRequest,
          isModuleActive,
          saveDecision,
          fetchRequest,
          unrealizeTrip,
        }) => {
          if (!isRequestLoaded) {
            return (
              <Wrapper>
                <Helmet title={trans('document.type-travel')} />
                <Main>
                  <LoadingOverlay />
                </Main>
              </Wrapper>
            )
          }
          return (
            <Wrapper>
              <Helmet title={trans('document.type-travel')} />
              <Main>
                <RequestBreadcrumb request={request} />
                <NonDelegationWrapper request={request}>
                  {request['status'] === constants.STATUS_DRAFT &&
                    this.renderDraft(request, {
                      currencies,
                      sendToAcceptance,
                      currentUser,
                      deleteRequest,
                      changeRequestValue,
                      updateRequest,
                      isModuleActive,
                      saveDecision,
                      ws,
                    })}
                  {request['status'] === constants.STATUS_WAITING_FOR_ACCEPTANCE &&
                    this.renderWaitingForAcceptance(
                      request,
                      {
                        cancelRequest,
                        currentUser,
                        isModuleActive,
                        changeRequestValue,
                        currencies,
                        instanceCurrency,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}
                  {request['status'] === constants.STATUS_UPCOMING_TRIP &&
                    this.renderUpcomingTrip(
                      request,
                      {
                        sendToSettlement,
                        instanceCurrency,
                        cancelRequest,
                        changeRequestValue,
                        fetchRequest,
                        unrealizeTrip,
                        isModuleActive,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}
                  {request['status'] === constants.STATUS_TRIP &&
                    this.renderTrip(
                      request,
                      {
                        sendToSettlement,
                        instanceCurrency,
                        cancelRequest,
                        fetchRequest,
                        unrealizeTrip,
                        changeRequestValue,
                        isModuleActive,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}
                  {request['status'] === constants.STATUS_SETTLEMENT &&
                    this.renderSettlement(
                      request,
                      {
                        instanceCurrency,
                        sendToAcceptanceOfSettlement,
                        fetchRequest,
                        changeRequestValue,
                        cancelRequest,
                        unrealizeTrip,
                        isModuleActive,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}
                  {request['status'] === constants.STATUS_ACCEPTANCE_OF_SETTLEMENT &&
                    this.renderAcceptanceOfSettlement(
                      request,
                      {
                        unrealizeTrip,
                        fetchRequest,
                        changeRequestValue,
                        isModuleActive,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}
                  {request['status'] === constants.STATUS_ACCOUNTING &&
                    this.renderAccounting(
                      request,
                      {
                        currentUser,
                        isModuleActive,
                        changeRequestValue,
                        fetchRequest,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}

                  {request['status'] === constants.STATUS_TRANSFER_ERROR &&
                    this.renderTransferError(
                      request,
                      {
                        currentUser,
                        isModuleActive,
                        changeRequestValue,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}

                  {request['status'] === constants.STATUS_TRANSFERRED &&
                    this.renderTransferred(
                      request,
                      {
                        currentUser,
                        isModuleActive,
                        changeRequestValue,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}

                  {request['status'] === constants.STATUS_REJECTED &&
                    this.renderRejected(
                      request,
                      {
                        currentUser,
                        isModuleActive,
                        changeRequestValue,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}
                  {request['status'] === constants.STATUS_CANCELED &&
                    this.renderCancelled(
                      request,
                      {
                        currentUser,
                        isModuleActive,
                        changeRequestValue,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}
                  {request['status'] === constants.STATUS_FINISH &&
                    this.renderFinish(
                      request,
                      {
                        currentUser,
                        isModuleActive,
                        changeRequestValue,
                      },
                      () => this.closePopup(isUnrealizeInProgress),
                      this.openPopup,
                    )}

                  {/* TODO: the same dialog is in TripRequestSummaryPage, extract it as separate component */}
                  <Dialog
                    open={this.state.isSetAsUnrealized}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.closePopup(isUnrealizeInProgress)}
                    aria-labelledby='alert-dialog-slide-title'
                    aria-describedby='alert-dialog-slide-description'
                    className='last-requests__dialog'
                  >
                    <Paper square>
                      <DialogTitle id='alert-dialog-slide-title'>
                        {(this.state.type === 'setAsUnrealized' ||
                          this.state.type === 'sendToSettlement') &&
                          trans('global.finish-trip-confirm')}
                      </DialogTitle>

                      <DialogContent className='last-requests__dialog-content'>
                        {trans('global.finish-trip-confirm-text')}
                      </DialogContent>

                      <DialogActions className='accommodation-confirm__dialog-actions'>
                        <Icon
                          className='last-requests__dialog-close'
                          type='close'
                          onClick={() => this.closePopup(isUnrealizeInProgress)}
                        />
                        <Button
                          danger
                          xxs
                          color='primary'
                          onClick={(e) =>
                            this.process(
                              e,
                              request,
                              unrealizeTrip,
                              sendToSettlement,
                              changeRequestValue,
                            )
                          }
                        >
                          {trans('deductions-widget.confirm-cancel-trip')}
                        </Button>
                        <Button
                          outline
                          xxs
                          onClick={() => this.closePopup(isUnrealizeInProgress)}
                          disabled={isUnrealizeInProgress}
                          color='primary'
                        >
                          {trans('default.close')}
                        </Button>
                      </DialogActions>
                    </Paper>
                  </Dialog>
                </NonDelegationWrapper>
              </Main>
              <Aside>
                <TripRequestRightPanel request={request} fetchRequest={fetchRequest} />
              </Aside>
            </Wrapper>
          )
        }}
      </TripRequestManager>
    )
  }
}

export { TripRequestPage }
export default { TripRequestPage }
