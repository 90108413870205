import PropTypes from 'prop-types'
import React from 'react'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { TripRequestSummary } from '../TripRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import PrintRequestDocuments from '../../PrintRequestDocuments'
import { AccountingDocuments } from '../../AccountingDocuments'
import { RequestAccountingSummary } from '../../RequestAccountingSummary'
import RequestCommentManager from '../../../containers/RequestCommentManager/RequestCommentManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class TripRequestPageFinish extends React.Component<any, any> {
  render() {
    const { request, fetchRequest } = this.props

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <AccountingDocuments
          request={request}
          fetchRequest={fetchRequest}
          disableUpload={true}
          disableDelete={true}
          showMileageAllowance={request.abilities.can_account_delegation}
        />

        <AccountingDocuments
          request={request}
          fetchRequest={fetchRequest}
          disableUpload={true}
          disableDelete={true}
          showMileageAllowance={!request.abilities.can_account_delegation}
          readOnly={true}
          showSummary={true}
          types={['travel', 'confirmation']}
        />

        <TripRequestSummary request={request} />

        <RequestAccountingSummary
          changeRequestValue={this.props.changeRequestValue}
          request={request}
          accountingBalance={request.accountingBalance || {}}
        />

        <PrintRequestDocuments request={request} />

        <RequestCommentManager request={request}>
          {({ comments }) => {
            return <RequestComments comments={comments} />
          }}
        </RequestCommentManager>
      </div>
    )
  }
}

TripRequestPageFinish.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export { TripRequestPageFinish }
export default { TripRequestPageFinish }
